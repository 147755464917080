/* .movement-comments-container {
    height: 60vh;
} */

.movement-comments-list {
    height: 70%;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: auto;
}

.movement-comments-modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.comment-input {
    position: relative;
}

.comment-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.comment-author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}