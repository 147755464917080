.linear-calendar-controls-container {
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  gap: 0.5em;
}

.filters-container {
  display: flex;
  gap: 0.5em;
  width: 100%;
  margin-bottom: 4px;
}


.linear-date-picker {
  width: 15em;
  width: 100%;
  min-width: 3em;
}

.linear-filter-hour,
.linear-status-filter,
.linear-filter-priority,
.linear-court-filter {
  width: 150px;
}

.linear-filter {
  width: fit-content;
  width: 20%;
}

@media (max-width: 1366px) {
  .linear-filter {
    width: fit-content;
    width: 24%;
  }
}

.movement-item-status-container {
  display: flex;
  gap: 3ch;
  align-items: center;
  justify-content: flex-start;
}

.movement-item-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 1ch;
  align-items: flex-start;
  padding: 0.65ch 17px;
  font-weight: 500;
}

.import-judicial-tag {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.movement-item-status-subcontainer {
  display: flex;
  gap: 0.5ch;
}

.movement-actions-container {
  position: absolute;
  right: 1ch;
  bottom: 10px;
}

@media (max-width: 960px) {
  .linear-calendar-controls-container {
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
    gap: 1em;
  }

  .filters-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-flow: column;
    gap: 1em;
    width: 100%;
  }

  .linear-filter {
    width: 100%;
    max-width: 100%;
  }

  .linear-date-change {
    align-self: center;
  }

  .linear-date-picker,
  .linear-filter-hour,
  .linear-status-filter,
  .linear-filter-priority,
  .linear-court-filter {
    width: 100%;
  }

  .movement-item-container {
    padding: 35px 0.65ch 5px 0.65ch;
    width: 100%;
  }

  .movement-item-status-container {
    display: flex;
    flex-flow: column;
    gap: 1.5ch;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .movement-item-status-subcontainer {
    display: flex;
    gap: 0.5ch;
  }

  .movement-actions-container {
    position: absolute;
    right: 10px;
    top: 5px;
    height: fit-content;
  }
}