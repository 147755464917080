.demo-wrapper {
    padding: 0px 30px;
    font-family: 'Montserrat', sans-serif;
}

.editor-border {
    border: solid 1px #c6c6c6;
    border-radius: 15px;
}

.draft-input {
    margin: auto;
    background-color: transparent;
    min-height: 45px;
    font-family: inherit;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4d4d4d;
}

.draft-input span {
    word-break: break-all;
}

.draft-input:hover {
    border-color: #59c8c9;
    border-right-width: 1px;
}

.color-picker-styled-input {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 175px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-colorpicker-modal-options {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-card {
    background-color: #eef5fa;
    z-index: 1;
}

.public-DraftStyleDefault-block {
    margin: 0px 0px 20px 0px !important;
    padding: 4px 11px !important;
    width: 100%;
}

/*Fix justified text*/
.public-DraftStyleDefault-ltr {
    text-align: inherit !important;
}

.opt {
    margin-bottom: 6px;
    display: flex;
}

.wdlike-opt:hover {
    background-color: #c5c5c5;
    cursor: pointer;
}

.wdlike-disabled {
    cursor: default;
    opacity: 0.5;
}

.rdw-center-aligned-block>.public-DraftStyleDefault-block>span {
    text-align: center;
    width: 100%;
    display: block;
}

.rdw-right-aligned-block>.public-DraftStyleDefault-block>span {
    text-align: right;
    width: 100%;
    display: block;
}

.editor-colorpicker-modal {
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    flex-direction: column;
    width: 200px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    z-index: 100;
    background: rgb(255, 255, 255);
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.editor-colorpicker-modal-options {
    margin: 5px auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
}

.rdw-colorpicker-cube {
    width: 22px;
    height: 22px;
    border: 1px solid #F1F1F1;
}

.rdw-colorpicker-option {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #BFBDBD inset;
}

.rdw-editor-toolbar {
    align-items: center;
}

.editor-download-options>label {
    width: 33.33%;
    text-align: center;
}

.exit-icon:hover {
    cursor: pointer;
}

.default-option-wrapper {
    /* border: 1px solid #F1F1F1; */
    padding: 5px;
    min-width: 27px;
    height: 27px;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #ffffff00;
    text-transform: capitalize;
}

.default-option-wrapper:hover {
    /* box-shadow: 1px 1px 0px #BFBDBD; */
    background: #c5c5c5;
}

.default-option-wrapper:active {
    /* box-shadow: 1px 1px 0px #BFBDBD inset; */
    background: #c5c5c5;
}

.default-option-active {
    /* box-shadow: 1px 1px 0px #BFBDBD inset; */
    background: #c5c5c5;
}

.default-option-disabled {
    opacity: 0.3;
    cursor: default;
}

.editor-upload-card {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.editor-popover-arrow {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
    transform: translateY(-50%) rotate(45deg);
    top: 0px;
    left: 16px;
}

.editor-popover-arrow-content::before {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    margin: auto;
    content: '';
    pointer-events: auto;
    border-radius: 0 0 2px;
    pointer-events: none;
}

.editor-fontfamily-dropdown {
    width: 125px;
}

.editor-fontsize-dropdown {
    width: 50px;
}

.editor-dropdown-carettoopen {
    border-top: 5px solid #777777;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.editor-dropdown-carettoclose {
    border-bottom: 5px solid #777777;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.editor-dropdown-wrapper {
    height: 27px;
    background: white;
    cursor: pointer;
    border: 1px solid #c5c5c5;
    margin: 0px;
    text-transform: capitalize;
    background: white;
}

.editor-dropdown-wrapper:focus {
    outline: none;
}

.editor-dropdown-wrapper:hover {
    border: 1px solid #969696;
    background-color: #FFFFFF;
}

.editor-dropdown-wrapper:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}

.caret-container:hover {
    border: 1px solid #969696;
    background-color: #c5c5c5;
}

.caret-container-open {
    border: 1px solid #969696;
    background-color: #969696;
}

.editor-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
}

.editor-dropdown-selectedtext:hover {
    color: "#fafafa";
}

.editor-fontfamily-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.editor-fontsize-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.editor-top-bar {
    width: 100%;
    border-radius: 0px;
    height: 30px;
    background-color: rgb(42, 87, 154);
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.editor-top-bar-icon:hover {
    cursor: pointer;
    background-color: #3e6db6;
}

.editor-top-bar-icon-disabled {
    opacity: 0.7;
}

.editor-exit-icon:hover {
    cursor: pointer;
    background-color: #e81123;
}

.collapse-expand-styled-text-container {
    color: black;
    word-break: break-all;
}

.collapse-expand-styled-text-container:hover {
    color: black
}

.collapse-expand-styled-text p {
    margin-bottom: 2px;
}

.expand-observation-button {
    border: none;
    box-shadow: none;
    text-align: center;
    border-color: #d9d9d9;
    background-color: transparent;
    width: 75%;
}

.expand-observation-button:hover {
    border: 1px solid #d9d9d9;
    background-color: transparent;
}

.edit-button {
    display: flex;
    align-items: center;
    width: 5%;
    height: 100%;
    background-color: inherit;
    position: absolute;
    left: 0px;
    top: 0px;
    justify-content: center;
}