.quick-act-icon {
    font-size: 17px;
    font-weight: bold;
}

.quick-act-button {
    width: 50px;
    padding: 5px;
    border-radius: 10px 2px 2px 10px;
}

.quick-act-dropdown-edited {
    margin-top: 10px;
    padding: 12px 5px;
    border-radius: 9px;
    box-shadow: 0px 3px 14px -3px;
    background-color: #ffff;
    min-width: 200px;
}

.ant-dropdown-menu {
    box-shadow: none;
}

.mobile-button {
    width: 50px;
    padding: 5px;
    background-color: transparent;
}

.ant-dropdown-menu-item:hover {
    background-color: #fff;
    color: #35B6BC;
}

.quick-actions-popover-arrow {
    margin-top: 10px;
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
    transform: translateY(-50%) rotate(45deg);
    top: 0px;
    right: 16px;

}

@media (max-width: 980px) {
    .quick-actions-popover-arrow {
        left: 16px;
        right: 0px;
    }
}

.quick-actions-popover-arrow-content::before {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    margin: auto;
    content: '';
    pointer-events: auto;
    border-radius: 0 0 2px;
    pointer-events: none;
}