.pdf-viewer {
    margin: 5px;
    border-radius: 8px;
    box-shadow: 0px 1px 10px 1px #afafaf;
}
.sigCanvas {
    border-radius: 8px;
    border: 1px solid #ccc;
}
.sigCanvas:hover {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' transform='rotate(75)' style='fill:black;font-size:24px;'><text y='50%'>✒️</text></svg>")
    16 0, auto;
}

.react-pdf__Page.prevPage {
    z-index: 1;
  }