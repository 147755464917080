.rounded-container {
    text-align: center;
    color: #fff;
    border-radius: 8px;
}
.done {
    background-color: #41b812;
    color: #fff;
}

.pending {
    background-color: #c7176f;
    color: #fff;
}
.movement_type {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
}
.sort-icon {
    cursor: pointer;
    color: #ccc;
}
.sort-icon:hover {
    color: #000;
}
.sort-icon:active {
    scale: 0.9;
}
.captcha-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}