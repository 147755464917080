.hidden .ant-table-row-expand-icon-cell span {
  display: none;
}

.files-mass-actions-container {
  gap: 10px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  margin: 10px 0px;
}

.files-mass-actions {
  gap: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.files-mass-actions-buttons {
  gap: 15px;
  display: flex;
}

.files-mass-actions>div {
  gap: 5px;
  display: flex;
  margin-right: auto;
}