.hidden .ant-table-row-expand-icon-cell span {
    display: none;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 16px 16px;
}
  
.ant-table table {
  border-collapse: collapse;
}