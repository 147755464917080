.turno-selector-wrapper {
  display: flex;
  grid-row-gap: 2em;
  row-gap: 2em;
  flex-direction: column;
}
.turno-selector-wrapper > .turno-custom-title {
  width: 65%;
}
.turno-selector-wrapper > .turno-custom-title > .t-input {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  grid-column: span 2;
}
@media (max-width: 760px) {
  .turno-selector-wrapper > .turno-custom-title > .t-input {
    grid-column: span 1;
  }
}
@media (max-width: 760px) {
  .turno-selector-wrapper > .turno-custom-title {
    width: 100%;
  }
}
.turno-selector-wrapper > .turno-selector-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2em;
  gap: 2em;
  position: relative;
}
@media (max-width: 760px) {
  .turno-selector-wrapper > .turno-selector-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.turno-selector-wrapper > .turno-selector-container > .t-calendar {
  grid-column: span 2;
}
@media (max-width: 760px) {
  .turno-selector-wrapper > .turno-selector-container > .t-calendar {
    grid-column: span 1;
  }
}
.turno-selector-wrapper > .turno-selector-container > .t-input {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  grid-column: span 2;
}
@media (max-width: 760px) {
  .turno-selector-wrapper > .turno-selector-container > .t-input {
    grid-column: span 1;
  }
}
.turno-selector-wrapper > .turno-selector-container > .t-resume {
  display: flex;
  flex-direction: row;
  grid-gap: 1em;
  gap: 1em;
}
.turno-selector-wrapper > .turno-selector-container > .t-resume > .t-resume-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.turno-selector-wrapper > .turno-selector-container > .t-resume > .t-resume-item > .t-resume-item-label {
  font-weight: bold;
  margin-left: 1em;
}
.turno-selector-wrapper > .turno-selector-container > .turno-selector-hours-container {
  position: relative;
  overflow-y: scroll;
  grid-column: span 2;
}
@media (max-width: 760px) {
  .turno-selector-wrapper > .turno-selector-container > .turno-selector-hours-container {
    grid-column: span 1;
  }
}
.turno-selector-wrapper > .turno-selector-container > .turno-selector-hours-container > .turno-selector-hours {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  column-span: 1fr;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.turno-selector-wrapper > .turno-selector-container > .turno-selector-hours-container > .turno-selector-hours > .turno-hour-button {
  display: block;
  width: 100%;
  padding: 7%;
  display: grid;
  place-content: center;
}
