.trial-days-counter {
    position: fixed;
    bottom: 17px;
    left: 20px;
    border: 0px;
    box-shadow: 0px 3px 14px -3px;
    line-height: 15px;
}

.ant-modal-close {
    color: rgb(48, 48, 48);
}