.parent {
  width: 100%;
}
.scan-header {
  background-color: #02194b;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}
.scan-main {
  padding: 2.5%;
}
.ant-form .ant-row {
  margin-left: 0;
  margin-right: 0;
}
